import type { FC } from "preact/compat";
import { mobileNavbarOpened } from "stores/navbar";
import { tv } from "tailwind-variants";

const mobileNavigationItemStyle = tv({
  base: "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium",
  variants: {
    active: {
      true: "border-purple-500 bg-purple-900 text-indigo-100 hover:bg-purple-800",
      false:
        "text-gray-300 hover:border-gray-300 hover:bg-neutral-800 hover:text-gray-200",
    },
  },
});

const NavbarMobileNavigationItem: FC<{
  href: string;
  label: string;
  active: boolean;
}> = (props) => {
  return (
    <a
      rel="prefetch"
      href={props.href}
      class={mobileNavigationItemStyle({ active: props.active })}
      onClick={() => mobileNavbarOpened.set(false)}
    >
      {props.label}
    </a>
  );
};

export default NavbarMobileNavigationItem;
