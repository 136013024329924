import type { FC } from "preact/compat";
import MobileNavigationItem from "./mobile-navigation-item";
import { mobileNavbarOpened } from "stores/navbar";
import { useStore } from "@nanostores/preact";

const NavbarMobileMenu: FC<{
  navigations: Array<{ href: string; label: string; active: boolean }>;
  hadAdminLogin: boolean;
}> = (props) => {
  const $isOpened = useStore(mobileNavbarOpened);

  return (
    <div class={`pb-2 lg:hidden ${$isOpened ? "" : "hidden"}`} id="mobile-menu">
      <div class="space-y-1 pt-2">
        {props.navigations.map((it) => (
          <MobileNavigationItem
            href={it.href}
            label={it.label}
            active={!!it.active}
          />
        ))}

        {props.hadAdminLogin && (
          <MobileNavigationItem
            href={"/admin"}
            label={"Admin"}
            active={false}
          />
        )}
      </div>
    </div>
  );
};

export default NavbarMobileMenu;
